$(function() {

	var $w = $(window);
	var $b = $(document.body);

	var scrollHandler = function() {
		if ($w.scrollTop() > window.innerHeight * 0.3) {
			var isNewlyScrolled = !$b.is(".scrolled");
			$b.addClass("scrolled");
			$b.addClass("no-transition");
			if (isNewlyScrolled) {
				$b.addClass("after-scroll");
				window.setTimeout(function() {
					$b.removeClass("no-transition");
					$b.removeClass("after-scroll");
				}, 400);
			} else {
				$b.removeClass("no-transition after-scroll");
			}
		} else {
			if ($b.is(".scrolled")) {
				$b.removeClass("scrolled");
				/*
				$b.removeClass("no-transition");
				$b.addClass("after-scroll");
				window.setTimeout(function() {
					$b.removeClass("after-scroll");
					$b.removeClass("scrolled");
				}, 500)
				*/
			}
		}
	};

	var throttledScrollHandler = $.throttle(500, scrollHandler);

	$w.scroll(throttledScrollHandler);
	scrollHandler();

});