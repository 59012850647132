$(function() {

	$("a.scrolllink").click(function() {
		$.scrollTo($(this).attr("href"), 500, {offset: -50});
		return false;
	});

	$("a.hide-after-click").click(function() {
		$(this).closest(".arrow").addClass("hidden");
	});

});
